@import "../scss/bootstrap.scss";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
$custom-typography: mat-typography-config(
  $font-family: '"Work Sans", sans-serif;',
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$think-abm-primary: mat-palette($mat-indigo);
$think-abm-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$think-abm-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$think-abm-theme: mat-light-theme(
  (
    color: (
      primary: $think-abm-primary,
      accent: $think-abm-accent,
      warn: $think-abm-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($think-abm-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.additional-container-padding {
  padding-left: 30px;
  padding-right: 30px;

  @media all and (max-width: 992px) {
    padding: 30px;
  }

  @media all and (min-width: 992px) {
    padding-top: 1rem;
  }
}

.full-width {
  width: 100%;
}

button:focus {
  outline: 1px solid #49aa73 !important;
}

.card-with-overflow {
  overflow-x: auto;
}

:host {
  ::ng-deep {
    .mat-option {
      font-family: "Work Sans", sans-serif;
      font-weight: 400;
    }

    .mat-form-field {
      font-family: "Work Sans", sans-serif;
      font-weight: 700;
    }
  }
}
